
@font-face {
  font-family: 'PixelFont';
  src: local('PixelFont'), url(./assets/fonts/pixel.woff) format('woff');
}

* {
  image-rendering: pixelated;
  outline: 0;
}

a {
	color: inherit;
    text-decoration: none;
}

body {
  margin: 0;
  font-family: PixelFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}